
import React, { Component } from "react";
import { Link, animateScroll as scroll, Element, Events } from "react-scroll";

export default class Navbar extends Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
      }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  handleClick = event => {
    document.getElementById("Content").classList.add("show-content");
    document.getElementById("closeTab").classList.add("closeTabVisible");
  }

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.handleClick.bind(this)}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="About"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.handleClick.bind(this)}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="featured"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.handleClick.bind(this)}
              >
                Featured Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="Artworks"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.handleClick.bind(this)}
              >
                Artworks
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="Contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.handleClick.bind(this)}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}