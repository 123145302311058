import React from "react";

import ReCAPTCHA from 'react-google-recaptcha';

import { useState } from "react";

export default function Contact() {
    const [mailerState, setMailerState] = useState({
        name: "",
        email: "",
        message: "",
      });

      function handleStateChange(e) {
        setMailerState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }

      const recaptchaRef = React.createRef();

      const [disableSubmit,setDisableSubmit] = useState(true);

      function onChange(value) {
        console.log('Captcha value:', value);
        if (value !== null) {
          setDisableSubmit(false);
        }
      }

      const submitEmail = async (e) => {
        e.preventDefault();
        console.log({ mailerState });
        fetch("https://api.pretzeloverdose.com/send", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ mailerState }),
        })
          .then((res) => res.json())
          .then(async (res) => {
            const resData = await res;
            console.log(resData);
            if (resData.status === "success") {
              alert("Message Sent");
            } else if (resData.status === "fail") {
              alert("Message failed to send");
            }
          })
          .then(() => {
            setMailerState({
              email: "",
              name: "",
              message: "",
            });
          });
      };

    return (
        <div className="section section-dark bottomSpace"><div className="section-content">
    <div className="middleBlock">
<form
       style={{
         justifyContent: "center",
         alignItems: "center",
       }}
       onSubmit={submitEmail}
        >
       <fieldset
         style={{
           marginTop: "100px",
           fontSize: "30px",
           display: "flex",
           flexDirection: "column",
           justifyContent: "center",
           width: "100%",
         }}
       >
         <legend>Contact Us</legend>
         <input
           placeholder="Name"
           onChange={handleStateChange}
           name="name"
           value={mailerState.name}
         />
         <input
           placeholder="Email"
           onChange={handleStateChange}
           name="email"
           value={mailerState.email}
         />
         <textarea
           style={{ minHeight: "200px" }}
           placeholder="Message"
           onChange={handleStateChange}
           name="message"
           value={mailerState.message}
         />

     <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Lf3I-4dAAAAALpzqAJGyl05p9Obn31L_03hvq80"
        onChange={onChange}
      />
         <button id="submitButton" disabled={disableSubmit}>Send Message</button>
       </fieldset>
     </form>
     </div>
     </div></div>
    );
        }