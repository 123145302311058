/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/cc.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    actions.idle.play()
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 4.70, -17]} scale={2.83}>
        <primitive object={nodes.Bone006} />
        <primitive object={nodes.Bone} />
        <skinnedMesh
          geometry={nodes.Cube004.geometry}
          material={materials['Material.005']}
          skeleton={nodes.Cube004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder001.geometry}
          material={materials['Material.003']}
          skeleton={nodes.Cylinder001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder002.geometry}
          material={materials['Material.008']}
          skeleton={nodes.Cylinder002.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder003.geometry}
          material={nodes.Cylinder003.material}
          skeleton={nodes.Cylinder003.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder004.geometry}
          material={nodes.Cylinder004.material}
          skeleton={nodes.Cylinder004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder005.geometry}
          material={nodes.Cylinder005.material}
          skeleton={nodes.Cylinder005.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder006.geometry}
          material={nodes.Cylinder006.material}
          skeleton={nodes.Cylinder006.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder007.geometry}
          material={nodes.Cylinder007.material}
          skeleton={nodes.Cylinder007.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder008.geometry}
          material={nodes.Cylinder008.material}
          skeleton={nodes.Cylinder008.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder009.geometry}
          material={nodes.Cylinder009.material}
          skeleton={nodes.Cylinder009.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder010.geometry}
          material={nodes.Cylinder010.material}
          skeleton={nodes.Cylinder010.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder011.geometry}
          material={nodes.Cylinder011.material}
          skeleton={nodes.Cylinder011.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder012.geometry}
          material={nodes.Cylinder012.material}
          skeleton={nodes.Cylinder012.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder013.geometry}
          material={nodes.Cylinder013.material}
          skeleton={nodes.Cylinder013.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder014.geometry}
          material={nodes.Cylinder014.material}
          skeleton={nodes.Cylinder014.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder015.geometry}
          material={nodes.Cylinder015.material}
          skeleton={nodes.Cylinder015.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder016.geometry}
          material={nodes.Cylinder016.material}
          skeleton={nodes.Cylinder016.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder018.geometry}
          material={nodes.Cylinder018.material}
          skeleton={nodes.Cylinder018.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder019.geometry}
          material={nodes.Cylinder019.material}
          skeleton={nodes.Cylinder019.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder020.geometry}
          material={nodes.Cylinder020.material}
          skeleton={nodes.Cylinder020.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cylinder021.geometry}
          material={nodes.Cylinder021.material}
          skeleton={nodes.Cylinder021.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane.geometry}
          material={materials['Material.002']}
          skeleton={nodes.Plane.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane001.geometry}
          material={materials['Material.001']}
          skeleton={nodes.Plane001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane003.geometry}
          material={nodes.Plane003.material}
          skeleton={nodes.Plane003.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane004.geometry}
          material={nodes.Plane004.material}
          skeleton={nodes.Plane004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane005.geometry}
          material={nodes.Plane005.material}
          skeleton={nodes.Plane005.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane009.geometry}
          material={nodes.Plane009.material}
          skeleton={nodes.Plane009.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane011.geometry}
          material={nodes.Plane011.material}
          skeleton={nodes.Plane011.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane012.geometry}
          material={nodes.Plane012.material}
          skeleton={nodes.Plane012.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane013.geometry}
          material={nodes.Plane013.material}
          skeleton={nodes.Plane013.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane018.geometry}
          material={nodes.Plane018.material}
          skeleton={nodes.Plane018.skeleton}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/cc.glb')
