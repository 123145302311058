
import { Link, animateScroll as scroll } from "react-scroll";
const about = (<div className="about"><p>Patrick began his programming journey in 2006 as a web designer. Over the years since, he has used programming languages such as Java, PHP, Python, C#,  C++, React, Angular and Node.</p>
<p>Patrick graduated from the University of Newcastle with a Bachelor of Computer Science in 2020.</p>
<p>Patrick has 7 years media industry experience developing web solutions for a broad range of national and international clients.</p>
<p>Looking to bring your business online or have an idea for a new App? <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Contact
              </Link> today to get a free consultation to get your business online with it's existing brand, or it's own unique new character.</p></div>);

export default about;