import { Link, animateScroll as scroll, Element } from "react-scroll";
import { useGLTF, useAnimations } from '@react-three/drei'
import ReactDOM from 'react-dom';
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, Html, useProgress } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";
import Cc from './Cc.js';
import Chefsite_dog1 from './Chefsite_dog1.js';
import Cs from './Cs.js';
import './App.css';
  /*
  useFrame((state) => {

    const t = state.clock.getElapsedTime()
    const peak = Math.sin(t / 5)
    if (peak > 0.5) {
      fadeIn = true
      fadeOut = false
    }
    if (peak < 0.5) {
      fadeOut = true
      fadeIn = false
    }
    if (fadeIn && dogBodyMat.current.opacity < 1) {
      dogBodyMat.current.opacity += 0.01
    }

    if (fadeOut && dogBodyMat.current.opacity > 0) {
      dogBodyMat.current.opacity -= 0.01
    }

  })
  */
  import React, { Component, useRef } from "react";
  import logo from "./logo.svg";
  import "./App.css";
  import Navbar from "./Navbar";
  import Section from "./Section";
  import dummyText from "./DummyText";
  import about from "./about";
  import Featured from "./Featured";
  import Artworks from "./Artworks";
  import Contact from "./Contact";
import { Group } from "three";



  function Loader() {
    const { active, progress, errors, item, loaded, total } = useProgress();

    return <Html center>{progress} % loaded</Html>;
  }
  function Cam() {
      const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
      })
      var up = true;
      useFrame((state) => {
        if (window.innerWidth > 800) {
          state.camera.zoom = window.innerWidth / 210
        } else {
          state.camera.zoom = window.innerWidth / 100
        }
        if (state.camera.position.x > 200) {
          up = false
        }
        if (state.camera.position.x < 0) {
          up = true
        }
        if (up)  {
          state.camera.position.x += 0.2
        } else {
          state.camera.position.x -= 0.2
        }

        state.camera.updateProjectionMatrix()
      });
      return null
  }
  class App extends Component {
    closeContent = event => {
      document.getElementById("Content").classList.remove("show-content");
      document.getElementById("closeTab").classList.remove("closeTabVisible");
    }

    render() {
      return (
        <div className="App">
          <div className="closeTab" id="closeTab" onClick={this.closeContent.bind(this)}>
            <b>x</b> close
          </div>
          <Navbar />
          <div className="Content" id="Content">
          <Element id="Home"><div className="section section-dark"><div className="section-content"><h1><b>Web</b> and <b>App Development</b><br />based in <b>Newcastle, Australia.</b></h1></div></div></Element>
          <Section
            title="About"
            subtitle={about}
            dark={false}
            id="About"
          />
          <Section
            title="Featured Projects"
            subtitle={Featured}
            dark={true}
            id="featured"
          />
          <Section id="Artworks"
            title="Artworks"
            subtitle={Artworks}
            dark={false}>
          </Section>
          <Element id="Contact">
          <Contact
          />
          </Element>
          </div>
          <div className="BG">
      <Canvas orthographic camera={{ zoom: 8, position: [40, 40, 70] }}>
        <Suspense fallback={<Loader />}>
      <pointLight position={[30, 20, 10]} intensity={0.2} />
          <Chefsite_dog1 />
          <Cc />
          <Cs />
          <OrbitControls />
          <Environment files="./images/venice_sunset_1k.hdr" background />
        <Cam />
        </Suspense>
      </Canvas>
          </div>
        </div>
      );
    }
  }

  export default App;