
import React from 'react';
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';

const IMAGES =
[
{
        src: "images/1.jpg",
        thumbnail: "images/thumbs/1.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},

{
        src: "images/2.jpg",
        thumbnail: "images/thumbs/2.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/3.jpg",
        thumbnail: "images/thumbs/3.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/4.jpg",
        thumbnail: "images/thumbs/4.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/5.jpg",
        thumbnail: "images/thumbs/5.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/6.jpg",
        thumbnail: "images/thumbs/6.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/7.jpg",
        thumbnail: "images/thumbs/7.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/8.jpg",
        thumbnail: "images/thumbs/8.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/9.jpg",
        thumbnail: "images/thumbs/9.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/10.jpg",
        thumbnail: "images/thumbs/10.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/11.jpg",
        thumbnail: "images/thumbs/11.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/12.jpg",
        thumbnail: "images/thumbs/12.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/13.jpg",
        thumbnail: "images/thumbs/13.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/14.jpg",
        thumbnail: "images/thumbs/14.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/15.jpg",
        thumbnail: "images/thumbs/15.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/16.jpg",
        thumbnail: "images/thumbs/16.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/17.jpg",
        thumbnail: "images/thumbs/17.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
},
{
        src: "images/18.jpg",
        thumbnail: "images/thumbs/18.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 200
}]

const Artworks = (<div className="artworksContainer">
    <p>Since childhood Patrick has enjoyed drawing and painting.</p>
    <p>Artworks for sale are available at <a href="https://bluethumb.com.au/patrick-rice-o-connor" target="_blank" rel="noreferrer">bluethumb.com.au/patrick-rice-o-connor</a></p>
    <p>Below are a few select artworks.</p>

    <Gallery images={IMAGES}/>
    </div>
);
export default Artworks;