/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/cs.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Cube001.geometry}
        material={nodes.Cube001.material}
        position={[2.08, 8.62, -24.01]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Cube002.geometry}
        material={nodes.Cube002.material}
        position={[2.08, 8.62, -24.01]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Plane006.geometry}
        material={nodes.Plane006.material}
        position={[-4.63, 2.91, -32.8]}
        rotation={[0, 0.01, 0]}
        scale={[1.05, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Plane007.geometry}
        material={nodes.Plane007.material}
        position={[1.31, 7.41, -35.96]}
        rotation={[-0.01, 0.02, 0.08]}
        scale={[1.05, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Cylinder022.geometry}
        material={nodes.Cylinder022.material}
        position={[-4.52, 2.98, -32.53]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Cube003.geometry}
        material={nodes.Cube003.material}
        position={[-0.08, 9.52, -36.5]}
        rotation={[-0.26, -0.35, 2.28]}
        scale={[1.05, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Plane008.geometry}
        material={nodes.Plane008.material}
        position={[5.03, 7.65, -36]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Cylinder023.geometry}
        material={nodes.Cylinder023.material}
        position={[1.52, 8.55, -24.6]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.49}
      />
      <mesh
        geometry={nodes.Cylinder024.geometry}
        material={nodes.Cylinder024.material}
        position={[3.79, 8.55, -24.84]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.49}
      />
      <mesh
        geometry={nodes.Cylinder025.geometry}
        material={nodes.Cylinder025.material}
        position={[3.07, 8.75, -22.76]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.49}
      />
      <mesh
        geometry={nodes.Plane010.geometry}
        material={nodes.Plane010.material}
        position={[5.41, 7.72, -35.42]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Cylinder028.geometry}
        material={nodes.Cylinder028.material}
        position={[-6.46, 2.53, -32.6]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Plane016.geometry}
        material={nodes.Plane016.material}
        position={[2.9, 5.91, -25.44]}
        scale={0.75}
      />
      <mesh
        geometry={nodes.Plane017.geometry}
        material={nodes.Plane017.material}
        position={[2.86, 5.81, -25.47]}
        scale={0.75}
      />
      <mesh geometry={nodes.Cube.geometry} material={nodes.Cube.material} position={[1.52, 8.55, -24.6]} scale={1.05} />
      <mesh
        geometry={nodes.Cylinder029.geometry}
        material={nodes.Cylinder029.material}
        position={[7.25, 10.48, -22.25]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.49}
      />
      <mesh
        geometry={nodes.Cube007.geometry}
        material={nodes.Cube007.material}
        position={[-15.29, 2.3, 3.42]}
        rotation={[Math.PI, -0.16, Math.PI]}
        scale={[1.05, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Cube011.geometry}
        material={materials['Material.013']}
        position={[-17.54, 9.59, -12.4]}
        rotation={[1.54, 0.3, 1.54]}
        scale={[1.05, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Plane002.geometry}
        material={nodes.Plane002.material}
        position={[0.71, 2.3, -18.95]}
        scale={20.8}
      />
      <mesh
        geometry={nodes.Plane014.geometry}
        material={materials['Material.011']}
        position={[-17.42, 5.98, -25.35]}
        scale={0.75}
      />
      <mesh
        geometry={nodes.Plane015.geometry}
        material={nodes.Plane015.material}
        position={[-17.46, 5.77, -25.38]}
        scale={0.75}
      />
      <mesh
        geometry={nodes.Cube013.geometry}
        material={nodes.Cube013.material}
        position={[-19.79, 10.2, -18.72]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[20.79, 8.49, 0.41]}
      />
      <mesh
        geometry={nodes.Cube012.geometry}
        material={nodes.Cube012.material}
        position={[0.76, 10.2, -39.58]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[20.79, 8.49, 0.41]}
      />
      <mesh
        geometry={nodes.Plane031.geometry}
        material={nodes.Plane031.material}
        position={[-19.19, 2.55, -27.73]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Plane032.geometry}
        material={nodes.Plane032.material}
        position={[-17.14, 2.55, -29.8]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Plane033.geometry}
        material={nodes.Plane033.material}
        position={[-12.8, 2.91, -1.53]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={[1.05, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Cube014.geometry}
        material={materials['Material.018']}
        position={[0, -0.99, -0.06]}
        scale={[52.52, 1.05, 55.47]}
      />
      <mesh
        geometry={nodes.Cube015.geometry}
        material={nodes.Cube015.material}
        position={[0, 0.24, -0.06]}
        scale={[52.52, 1.05, 55.47]}
      />
      <mesh
        geometry={nodes.Cube016.geometry}
        material={nodes.Cube016.material}
        position={[-0.03, 0.01, 58.67]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[52.52, 1.05, 55.47]}
      />
      <mesh
        geometry={nodes.Sphere004.geometry}
        material={nodes.Sphere004.material}
        position={[-6.85, 7.07, 5.04]}
        scale={2.41}
      />
      <mesh
        geometry={nodes.Sphere005.geometry}
        material={nodes.Sphere005.material}
        position={[-2.83, 5.82, 5.04]}
        scale={1.22}
      />
      <mesh
        geometry={nodes.Cylinder026.geometry}
        material={nodes.Cylinder026.material}
        position={[-5.63, 1.35, 2.38]}
        scale={[0.57, 2.56, 0.57]}
      />
      <mesh
        geometry={nodes.Sphere006.geometry}
        material={nodes.Sphere006.material}
        position={[3.68, 7.07, 5.04]}
        scale={2.41}
      />
      <mesh
        geometry={nodes.Sphere007.geometry}
        material={nodes.Sphere007.material}
        position={[7.7, 5.82, 5.04]}
        scale={1.22}
      />
      <mesh
        geometry={nodes.Cylinder027.geometry}
        material={nodes.Cylinder027.material}
        position={[4.9, 1.35, 2.38]}
        scale={[0.57, 2.56, 0.57]}
      />
      <mesh
        geometry={nodes.Sphere008.geometry}
        material={nodes.Sphere008.material}
        position={[-16.51, 7.4, 5.04]}
        scale={2.41}
      />
      <mesh
        geometry={nodes.Sphere009.geometry}
        material={nodes.Sphere009.material}
        position={[-12.49, 6.14, 5.04]}
        scale={1.22}
      />
      <mesh
        geometry={nodes.Cylinder051.geometry}
        material={nodes.Cylinder051.material}
        position={[-15.29, 1.68, 2.38]}
        scale={[0.57, 2.56, 0.57]}
      />
      <mesh
        geometry={nodes.Sphere010.geometry}
        material={nodes.Sphere010.material}
        position={[14.43, 7.07, 5.04]}
        scale={2.41}
      />
      <mesh
        geometry={nodes.Sphere011.geometry}
        material={nodes.Sphere011.material}
        position={[18.45, 5.82, 5.04]}
        scale={1.22}
      />
      <mesh
        geometry={nodes.Cylinder052.geometry}
        material={nodes.Cylinder052.material}
        position={[15.64, 1.35, 2.38]}
        scale={[0.57, 2.56, 0.57]}
      />
      <mesh
        geometry={nodes.Plane019.geometry}
        material={nodes.Plane019.material}
        position={[-42.41, 0.14, 40.57]}
        scale={[5.25, 1.05, 1.05]}
      />
      <mesh
        geometry={nodes.Plane034.geometry}
        material={materials['Material.003']}
        position={[24.47, 1.66, -18.75]}
        scale={[28.5, 7.01, 7.09]}
      />
      <mesh
        geometry={nodes.Cube017.geometry}
        material={nodes.Cube017.material}
        position={[-16.96, 6.03, -27.17]}
        rotation={[-0.48, 0.83, -0.06]}
        scale={[0.15, -4.35, 0.15]}
      />
      <mesh
        geometry={nodes.Cube018.geometry}
        material={nodes.Cube018.material}
        position={[-15.05, 6, -29.51]}
        rotation={[-0.48, 0.83, 0.78]}
        scale={[-0.15, -4.35, 0.15]}
      />
      <mesh
        geometry={nodes.Cube019.geometry}
        material={nodes.Cube019.material}
        position={[-15.7, 5.36, -28.06]}
        rotation={[-0.48, 0.83, 1.94]}
        scale={[-0.15, -2.37, 0.28]}
      />
      <mesh
        geometry={nodes.Cube020.geometry}
        material={nodes.Cube020.material}
        position={[-17.79, 5.44, -29.82]}
        rotation={[0.64, 0.78, -0.48]}
        scale={[-0.14, -3.45, 0.14]}
      />
      <mesh
        geometry={nodes.Cube021.geometry}
        material={nodes.Cube021.material}
        position={[-16.66, 8.52, -28.92]}
        rotation={[-0.48, 0.83, 1.94]}
        scale={[-0.15, -0.7, 0.28]}
      />
      <mesh
        geometry={nodes.Circle.geometry}
        material={nodes.Circle.material}
        position={[23.56, 1.23, -4.65]}
        scale={5.7}
      />
      <mesh
        geometry={nodes.Circle001.geometry}
        material={nodes.Circle001.material}
        position={[23.56, 1.06, -33.07]}
        scale={5.7}
      />
      <mesh
        geometry={nodes.Sphere012.geometry}
        material={nodes.Sphere012.material}
        position={[26.7, 3.67, -6.16]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.77, 0.77, 0.77]}
      />
      <mesh
        geometry={nodes.Sphere013.geometry}
        material={nodes.Sphere013.material}
        position={[25.3, 3.59, -8.26]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        geometry={nodes.Cylinder053.geometry}
        material={nodes.Cylinder053.material}
        position={[24.88, 0.73, -5.82]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.37, 1.64, 0.37]}
      />
      <mesh
        geometry={nodes.Sphere014.geometry}
        material={nodes.Sphere014.material}
        position={[26.4, 3.67, -2.17]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.77, 0.77, 0.77]}
      />
      <mesh
        geometry={nodes.Sphere015.geometry}
        material={nodes.Sphere015.material}
        position={[25, 3.59, -4.26]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        geometry={nodes.Cylinder054.geometry}
        material={nodes.Cylinder054.material}
        position={[24.58, 0.73, -1.83]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.37, 1.64, 0.37]}
      />
      <mesh
        geometry={nodes.Sphere016.geometry}
        material={nodes.Sphere016.material}
        position={[29.09, 3.67, -5.47]}
        rotation={[-Math.PI, -0.53, -Math.PI]}
        scale={[0.77, 0.77, 0.77]}
      />
      <mesh
        geometry={nodes.Sphere017.geometry}
        material={nodes.Sphere017.material}
        position={[26.92, 3.59, -4.2]}
        rotation={[-Math.PI, -0.53, -Math.PI]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        geometry={nodes.Cylinder055.geometry}
        material={nodes.Cylinder055.material}
        position={[29.33, 0.73, -3.64]}
        rotation={[-Math.PI, -0.53, -Math.PI]}
        scale={[0.37, 1.64, 0.37]}
      />
      <mesh
        geometry={nodes.Sphere018.geometry}
        material={nodes.Sphere018.material}
        position={[26.7, 3.67, -34.14]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.77, 0.77, 0.77]}
      />
      <mesh
        geometry={nodes.Sphere019.geometry}
        material={nodes.Sphere019.material}
        position={[25.3, 3.59, -36.24]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        geometry={nodes.Sphere020.geometry}
        material={nodes.Sphere020.material}
        position={[26.4, 3.67, -30.15]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.77, 0.77, 0.77]}
      />
      <mesh
        geometry={nodes.Sphere021.geometry}
        material={nodes.Sphere021.material}
        position={[25, 3.59, -32.25]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        geometry={nodes.Cylinder056.geometry}
        material={nodes.Cylinder056.material}
        position={[24.58, 0.73, -29.81]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.37, 1.64, 0.37]}
      />
      <mesh
        geometry={nodes.Sphere022.geometry}
        material={nodes.Sphere022.material}
        position={[29.09, 3.67, -33.46]}
        rotation={[-Math.PI, -0.53, -Math.PI]}
        scale={[0.77, 0.77, 0.77]}
      />
      <mesh
        geometry={nodes.Sphere023.geometry}
        material={nodes.Sphere023.material}
        position={[26.92, 3.59, -32.18]}
        rotation={[-Math.PI, -0.53, -Math.PI]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        geometry={nodes.Cylinder057.geometry}
        material={nodes.Cylinder057.material}
        position={[29.33, 0.73, -31.62]}
        rotation={[-Math.PI, -0.53, -Math.PI]}
        scale={[0.37, 1.64, 0.37]}
      />
      <mesh
        geometry={nodes.Cube022.geometry}
        material={nodes.Cube022.material}
        position={[0.62, 2.76, 1.69]}
        scale={[20.79, -1.19, 0.41]}
      />
      <mesh
        geometry={nodes.Cube023.geometry}
        material={nodes.Cube023.material}
        position={[21.27, 2.76, -6.66]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[8.83, -1.19, 0.41]}
      />
      <mesh
        geometry={nodes.Cylinder058.geometry}
        material={nodes.Cylinder058.material}
        position={[24.88, 0.73, -33.84]}
        rotation={[-Math.PI, 0.98, -Math.PI]}
        scale={[0.37, 1.64, 0.37]}
      />
      <mesh
        geometry={nodes.Cube024.geometry}
        material={nodes.Cube024.material}
        position={[21.27, 2.76, -30.71]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[8.83, -1.19, 0.41]}
      />
      <mesh
        geometry={nodes.Cube005.geometry}
        material={materials['Material.020']}
        position={[25.67, 6.89, -24.82]}
        rotation={[0, 1.2, 0]}
        scale={[0.87, 0.87, 0.87]}
      />
      <mesh
        geometry={nodes.Cube025.geometry}
        material={materials['Material.007']}
        position={[25.67, 3.24, -24.81]}
        rotation={[0, 1.2, 0]}
        scale={[0.45, 2.79, 0.45]}
      />
      <mesh
        geometry={nodes.Plane035.geometry}
        material={nodes.Plane035.material}
        position={[-17.14, 2.43, -29.57]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Cube026.geometry}
        material={nodes.Cube026.material}
        position={[23.5, 5.65, 5.54]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.05, 1.05, 0.19]}
      />
      <mesh
        geometry={nodes.Cube027.geometry}
        material={nodes.Cube027.material}
        position={[22.8, 5.65, 9.85]}
        scale={[1.05, 1.05, 0.19]}
      />
      <mesh
        geometry={nodes.Cube028.geometry}
        material={nodes.Cube028.material}
        position={[23.8, 5.65, -43.33]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[1.05, 1.05, 0.19]}
      />
      <mesh
        geometry={nodes.Cube029.geometry}
        material={nodes.Cube029.material}
        position={[22.8, 5.65, -54.34]}
        scale={[1.05, 1.05, 0.19]}
      />
      <mesh
        geometry={nodes.Cube030.geometry}
        material={nodes.Cube030.material}
        position={[11.8, 4.62, -5.37]}
        scale={[6.9, 2.13, -4.95]}
      />
      <mesh
        geometry={nodes.Cube031.geometry}
        material={nodes.Cube031.material}
        position={[16.57, 7.19, -6.73]}
        rotation={[0, -1.4, 0]}
        scale={[1.66, 0.51, -1.19]}
      />
      <mesh
        geometry={nodes.Cube032.geometry}
        material={nodes.Cube032.material}
        position={[16.57, 7.19, -3.01]}
        rotation={[Math.PI, -1.55, Math.PI]}
        scale={[1.66, 0.51, -1.19]}
      />
      <mesh
        geometry={nodes.Cube033.geometry}
        material={nodes.Cube033.material}
        position={[-37.49, 34.69, 5.84]}
        scale={[6.9, 2.13, -4.95]}
      />
      <mesh
        geometry={nodes.Cube034.geometry}
        material={nodes.Cube034.material}
        position={[-50.66, 5.64, -53.5]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.05, 1.05, 0.19]}
      />
      <mesh
        geometry={nodes.Sphere024.geometry}
        material={nodes.Sphere024.material}
        position={[-42.34, 19.85, -40.17]}
        rotation={[0, 0.75, 0]}
        scale={[7.03, 7.03, 7.03]}
      />
      <mesh
        geometry={nodes.Sphere025.geometry}
        material={nodes.Sphere025.material}
        position={[-33.74, 16.18, -48.14]}
        rotation={[0, 0.75, 0]}
        scale={[3.56, 3.56, 3.56]}
      />
      <mesh
        geometry={nodes.Cylinder059.geometry}
        material={nodes.Cylinder059.material}
        position={[-45.02, 3.16, -48.28]}
        rotation={[0, 0.75, 0]}
        scale={[1.67, 7.48, 1.67]}
      />
      <mesh
        geometry={nodes.Sphere026.geometry}
        material={nodes.Sphere026.material}
        position={[-16.51, 10.7, -35.69]}
        scale={2.41}
      />
      <mesh
        geometry={nodes.Sphere027.geometry}
        material={nodes.Sphere027.material}
        position={[-12.49, 9.44, -35.69]}
        scale={1.22}
      />
      <mesh
        geometry={nodes.Cylinder060.geometry}
        material={nodes.Cylinder060.material}
        position={[-15.29, 4.98, -38.35]}
        scale={[0.57, 2.56, 0.57]}
      />
      <mesh
        geometry={nodes.Cylinder061.geometry}
        material={nodes.Cylinder061.material}
        position={[-14.53, 4.4, -35.54]}
        scale={1.05}
      />
      <mesh
        geometry={nodes.Cylinder062.geometry}
        material={nodes.Cylinder062.material}
        position={[-8.18, 5.44, -22.3]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.49}
      />
      <mesh
        geometry={nodes.Cube045.geometry}
        material={nodes.Cube045.material}
        position={[1.38, 1.74, -11.44]}
        scale={[9.07, 0.76, 9.07]}
      />
      <mesh
        geometry={nodes.Cube056.geometry}
        material={nodes.Cube056.material}
        position={[-50.32, 5.01, -22.29]}
        scale={[0.15, 1.05, 31.92]}
      />
      <mesh
        geometry={nodes.Cube057.geometry}
        material={nodes.Cube057.material}
        position={[-13.46, 5.01, 9.44]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.15, 1.05, 36.63]}
      />
      <mesh
        geometry={nodes.Cube058.geometry}
        material={nodes.Cube058.material}
        position={[-13.46, 5.01, -54.1]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.15, 1.05, 36.63]}
      />
      <mesh
        geometry={nodes.Cube059.geometry}
        material={nodes.Cube059.material}
        position={[23.51, 5.01, -48.25]}
        scale={[0.15, 1.05, 5.82]}
      />
      <mesh
        geometry={nodes.Cube060.geometry}
        material={nodes.Cube060.material}
        position={[23.22, 5.01, 6.93]}
        scale={[0.15, 1.05, 2.28]}
      />
    </group>
  )
}

useGLTF.preload('/cs.glb')
