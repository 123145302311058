/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/chefsite_dog1.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    actions.ArmatureAction.play()
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Armature" position={[-35, 3.5, -5]} rotation={[-Math.PI / 2, 0, 0]} scale={2}>
        <primitive object={nodes.Bone} />
        <primitive object={nodes.Bone003} />
        <primitive object={nodes.Bone005} />
        <primitive object={nodes.Bone008} />
        <primitive object={nodes.Bone011} />
        <primitive object={nodes.Bone014} />
        <skinnedMesh
          geometry={nodes.Cube004.geometry}
          material={materials['Material.023']}
          skeleton={nodes.Cube004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Sphere028.geometry}
          material={materials['Material.012']}
          skeleton={nodes.Sphere028.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Sphere028_1.geometry}
          material={materials['Material.022']}
          skeleton={nodes.Sphere028_1.skeleton}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/chefsite_dog1.glb')
